import React, { useEffect, useCallback, useState } from "react";
import { useFormik } from "formik";
import {
    MyInput,
    Textarea,
    PriceInput,
    CityAutoCompleteInput,
    SelectInput,
} from "@/Components/Form/Fields";
import InputMask from "react-input-mask";
import { SlSpeedometer } from "react-icons/sl";
import { IoLocationOutline } from "react-icons/io5";
import { PiEngineLight } from "react-icons/pi";
import { TbManualGearbox } from "react-icons/tb";
import { FaOilCan } from "react-icons/fa";
import { ReactComponent as CarChassis } from "@/Utils/CarChassis.svg";
import { editAdSchema } from "@/Utils/validationSchema";
import { editAds } from "@/Services/api";
import { useNavigate, useParams } from "react-router-dom";
import { fuelList, gearboxList, automotiveDriveList } from "@/Utils/optionsTypes";
import { SmallSpiner } from "@/Components/Layout/LoadingSpinner";
import { useTelegram } from "@/Hooks/useTelegram";
import { toast } from "react-toastify";
import { cleanPhoneNumber } from "@/Utils/cleanPhoneNumber";
import { ConditionalSubmitButton } from "../CreateAnnouncement";

const MainInfoTab = ({ adData, setAdData }) => {
    const { tg } = useTelegram();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();

    const formik = useFormik({
        initialValues: {
            price: 0.0, // 0.0
            discounted_price: 0.0,
            currency: "USD",
            isNegotiable: false,
            selectedCity: "",
            selectedFuel: "",
            selectedGearbox: "",
            motor: 0.0,
            mileage: 0,
            driveType: "",
            description: "",
            vincode: "",
            car_number: "",
            phone: "",
        },
        validationSchema: editAdSchema,
        onSubmit: async (values) => {
            setLoading(true);

            try {
                // values.files.forEach((file) => {
                //     formData.append("photos", file);
                // });
                const body = {
                    make: adData.make,
                    model: adData.model,
                    car_year: adData.car_year,
                    price: Number(values.price),
                    discounted_price: Number(values.discounted_price),
                    vincode: values.vincode,
                    currency: values.currency,
                    is_negotiable: Boolean(values.isNegotiable),
                    gearbox: values.selectedGearbox,
                    fuel: values.selectedFuel,
                    motor: Number(values.motor),
                    mileage: Number(values.mileage),
                    drive_type: values.driveType,
                    description: values.description,
                    location: values.selectedCity,
                    phone: cleanPhoneNumber(values.phone),
                    car_number: values.car_number.replace(/\s/g, ''),
                };


                const res = await editAds(id, body)

                if (res.status >= 200 && res.status < 300) {
                    formik.resetForm();
                    tg?.HapticFeedback?.notificationOccurred('success');
                    toast.success("Оголошення успішно відредаговано");
                    // redirect to profile
                    navigate("/profile");
                } else {
                    tg?.HapticFeedback?.notificationOccurred('error');
                    toast.error("Помилка при редагуванні оголошення");
                    return
                }
                // reset form
            } catch (error) {
                console.log(error)
                tg?.HapticFeedback?.notificationOccurred('error');
                toast.error("Помилка при редагуванні оголошення");
            } finally {
                setLoading(false);
            }
        },
    });


    const handleFocus = (e) => {
        setTimeout(() => {
            e.target.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
        }, 300);  // Небольшая задержка для корректной работы на всех устройствах
    };

    useEffect(() => {
        formik.setValues({
            price: adData.price,
            discounted_price: adData.discounted_price === 0 ? adData.price : adData.discounted_price,
            currency: adData.currency,
            isNegotiable: adData.is_negotiable,
            selectedMake: adData.make,
            selectedModel: adData.model,
            selectedYear: adData.year,
            selectedCity: adData.location,
            selectedFuel: adData.fuel,
            selectedGearbox: adData.gearbox,
            motor: adData.motor,
            mileage: adData.mileage,
            driveType: adData.drive_type,
            description: adData.description,
            vincode: adData.vincode,
            car_number: adData.car_number,
            phone: adData.phone,
        });
    }, [adData])

    return (
        <div className="min-h-screen py-4">
            <form onSubmit={formik.handleSubmit} className="flex gap-4 p-2 flex-col">
                <div className="tg_section_bg rounded-lg p-2">
                    <div className="flex items-start gap-2 py-2">
                        <PriceInput
                            name="discounted_price"
                            currency={formik.values.currency}
                            setCurrency={(value) => formik.setFieldValue("currency", value)}
                            isNegotiable={formik.values.isNegotiable}
                            setIsNegotiable={(value) => formik.setFieldValue("isNegotiable", value)}
                            value={formik.values.discounted_price}
                            setPrice={(value) => formik.setFieldValue("discounted_price", value)}
                            error={formik.touched.discounted_price && formik.errors.discounted_price}
                        />
                    </div>
                    <div className="grid grid-cols-2 tg_text text-sm gap-y-4 dark:gap-y-4 py-2 gap-1">
                        <div className="flex items-start gap-2">
                            <MyInput
                                {...formik}
                                name="mileage"
                                className="w-full p-2 border border-gray-300 rounded outline-none focus:border-blue-500"
                                value={formik.values.mileage}
                                onChange={(value) => formik.setFieldValue("mileage", value)}
                                placeholder="Пробіг (км) (тис. км)"
                                label="Пробіг (тис. км)"
                                icon={<SlSpeedometer className="text-xl" />}
                                error={formik.touched.mileage && formik.errors.mileage}
                            />
                        </div>
                        <div className="flex items-start gap-2">
                            <CityAutoCompleteInput
                                name="selectedCity"
                                label="Місцезнаходження"
                                endpoint="/api/autocomplete-cities"
                                value={formik.values.selectedCity}
                                onChange={(value) => formik.setFieldValue("selectedCity", value)}
                                icon={<IoLocationOutline className="text-xl" />}
                                error={formik.touched.selectedCity && formik.errors.selectedCity}
                            />
                        </div>
                        <div className="flex items-start gap-2">
                            <SelectInput
                                id="fuel"
                                name="fuel"
                                data={fuelList}
                                value={formik.values.selectedFuel}
                                onChange={(value) => formik.setFieldValue("selectedFuel", value)}
                                label={"Тип палива"}
                                icon={<FaOilCan className="text-xl" />}
                                error={formik.touched.selectedFuel && formik.errors.selectedFuel}
                            />
                        </div>
                        <div className="flex items-start gap-2">
                            <SelectInput
                                id="gearbox"
                                name="gearbox"
                                data={gearboxList}
                                value={formik.values.selectedGearbox}
                                onChange={(value) => formik.setFieldValue("selectedGearbox", value)}
                                label={"Коробка передач"}
                                icon={<TbManualGearbox className="text-xl" />}
                                error={formik.touched.selectedGearbox && formik.errors.selectedGearbox}
                            />
                        </div>
                        <div className="flex items-start gap-2">
                            <MyInput
                                className="w-full p-2 border border-gray-300 rounded outline-none focus:border-blue-500"
                                type="number"
                                name="motor"
                                value={formik.values.motor}
                                onChange={(value) => formik.setFieldValue("motor", value)}
                                placeholder="Об'єм двигуна"
                                label="Об'єм двигуна"
                                icon={<PiEngineLight className="text-xl" />}
                                error={formik.touched.motor && formik.errors.motor}
                            />
                        </div>
                        <div className="flex items-start gap-2">
                            <SelectInput
                                id="driveType"
                                name="driveType"
                                data={automotiveDriveList}
                                value={formik.values.driveType}
                                onChange={(value) => formik.setFieldValue("driveType", value)}
                                label={"Привід"}
                                icon={<CarChassis className="w-[20xp] h-[20px]" />}
                                error={formik.touched.driveType && formik.errors.driveType}
                            />
                        </div>
                    </div>
                    <div className="flex flex-row justify-between items-center mt-4 text-sm tg_text_hint gap-2 ">
                        <div className="avtonumber relative h-[40px] flex items-center border-2 border-blue-500 rounded-md dark:border-blue-800 basis-1/2">
                            <span className="bg-blue-500 px-1 dark:bg-blue-800 text-white min-h-[38px] flex items-center">
                                UA
                            </span>
                            <MyInput
                                className=" w-full p-2 border border-gray-300 rounded outline-none focus:border-blue-500"
                                type="text"
                                name="car_number"
                                value={formik.values.car_number}
                                onChange={(value) => formik.setFieldValue("car_number", value)}
                                placeholder="Номер автомобіля"
                                error={formik.touched.car_number && formik.errors.car_number}
                            />
                        </div>
                        <div className="avtonumber relative flex items-center border-2 border-blue-500 rounded-md dark:border-blue-800 basis-1/2">
                            <MyInput
                                className="w-full p-2 border border-gray-300 rounded outline-none focus:border-blue-500"
                                type="text"
                                name="vincode"
                                value={formik.values.vincode}
                                onChange={(value) => formik.setFieldValue("vincode", value)}
                                placeholder="Вінкод"
                                error={formik.touched.vincode && formik.errors.vincode}
                            />
                        </div>
                    </div>
                    <div className="flex items-start gap-2 mt-4">
                        <InputMask
                            mask="+38 (999) 999 99 99"
                            name="phone"
                            value={formik.values.phone}
                            onChange={(e) => formik.setFieldValue("phone", e.target.value)}
                            onBlur={formik.handleBlur}
                            inputmode="numeric"
                            onFocus={handleFocus}
                        >
                            {(inputProps) => (
                                <div className="w-full">
                                    <label className="text-sm tg_text">Телефон</label>
                                    <input
                                        type="text"
                                        inputmode="numeric"
                                        {...inputProps}
                                        className="w-full p-2 border rounded outline-none focus:border-blue-500 className=w-full p-2 border border-gray-300 rounded outline-none tg_text tg_bg_secondary"
                                        placeholder="Телефон"
                                    />
                                    {formik.touched.phone && formik.errors.phone && (
                                        <span className="text-red-500">{formik.errors.phone}</span>
                                    )}
                                </div>
                            )}
                        </InputMask>
                    </div>
                    <div className="mt-2 text-left tg_text_hint text-sm">
                        <p>Дата публікації: {new Date().toLocaleDateString()}</p>
                    </div>
                </div>
                <div className="tg_section_bg rounded-lg tg_text p-2 pt-0">
                    <h3 className="text-lg font-semibold">Опис</h3>
                    <Textarea
                        name="description"
                        className="w-full p-2 border border-gray-300 rounded outline-none focus:border-blue-500"
                        value={formik.values.description}
                        onChange={(e) => formik.setFieldValue("description", e.target.value)}
                        placeholder="Опис автомобіля. Роскажіть максимально детально про ваше авто, які є плюси та мінуси. Чи були ДТП, які роботи проводились, тощо."
                        error={formik.touched.description && formik.errors.description}
                        onFocus={handleFocus}
                    />
                </div>
                {/* <button type={loading ?
                    "button" : "submit"
                }
                    className={`flex items-center gap-2 justify-center uppercase font-semibold mt-4 text-white py-2 px-4 rounded ${loading ? 'tg_bg_secondary' : 'bg-blue-500'}`}>
                    Зберегти зміни {loading && <SmallSpiner color={"white"} />}
                </button> */}
                <ConditionalSubmitButton loading={loading} onClick={formik.handleSubmit} textLabel={{
                    main: "Редагувати",
                    loading: "Зачекайте...",
                }} />
            </form>
        </div>
    );
};

export default MainInfoTab;
