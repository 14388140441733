import React, { useState, useEffect, useCallback, useRef } from 'react';
import { AutoComplete, InputGroup, Loader, Input } from 'rsuite';
import debounce from 'lodash.debounce';
import { useDropzone } from 'react-dropzone';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import { SelectPicker } from 'rsuite';
import Select from 'react-select';
import 'react-photo-view/dist/react-photo-view.css';
import { getSuggestions, getCitySuggestions } from '@/Services/api';
import { TbRuler2 } from 'react-icons/tb';

export const CitySearchInput = React.forwardRef(({ type, name, required, className, value, onChange, placeholder, icon, error, label, ...props }, ref) => {
    const [suggestions, setSuggestions] = useState([]);
    const [inputValue, setInputValue] = useState(value || '');
    const [openSuggestions, setOpenSuggestions] = useState(false);

    const objRef = useRef();

    const debouncedFetchSuggestions = useRef(
        debounce(async (query) => {
            if (query.length >= 3) { // Запросы делаются только если длина строки больше 3 символов
                const data = await getCitySuggestions(query);
                setSuggestions(data);
            } else {
                setSuggestions([]);
            }
        }, 300)
    ).current;

    useEffect(() => {
        // Обрабатываем изменения inputValue и запускаем дебаунс запрос
        debouncedFetchSuggestions(inputValue);
    }, [inputValue, debouncedFetchSuggestions]);

    const handleChange = (value) => {
        setInputValue(value || ''); // Убедимся, что value не null
        onChange(value); // Вызов onChange для обновления родительского состояния
    };

    const handleSuggestionClick = (suggestion) => {
        setInputValue(suggestion.name);
        onChange(suggestion.name);
        setSuggestions([]); // Скрываем подсказки после выбора
    };

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (objRef.current && !objRef.current.contains(e.target)) {
                setOpenSuggestions(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [objRef]);

    const onBlurred = () => {
        setTimeout(() => {
            setOpenSuggestions(false);
        }, 200);
    };

    useEffect(() => {
        setInputValue(value || ''); // Синхронизируем inputValue с внешним value
    }, [value]); // Этот useEffect будет срабатывать при изменении value из props

    return (
        <div className='flex flex-col gap-1 w-full relative' ref={objRef}>
            {label && (
                <label>
                    {label}
                    {required && <span className="text-red-500">*</span>}
                </label>
            )}
            <InputGroup inside className="w-full">
                {icon && <InputGroup.Addon>{icon}</InputGroup.Addon>}
                <Input
                    ref={ref}
                    placeholder={placeholder}
                    onChange={handleChange}
                    onFocus={() => setOpenSuggestions(true)}
                    onBlur={onBlurred}
                    value={inputValue}
                    {...props}
                    className="w-full p-2 border border-gray-300 rounded outline-none tg_text tg_bg_secondary text-base"
                />
            </InputGroup>
            {suggestions.length > 0 && openSuggestions && (
                <div className="suggestions-list tg_bg_secondary w-full tg_text rounded shadow-lg mt-2 z-10 max-h-[300px] overflow-auto">
                    {suggestions.map((suggestion, index) => (
                        <div
                            key={index}
                            className="p-2 cursor-pointer"
                            onClick={() => handleSuggestionClick(suggestion)}
                        >
                            {suggestion.name} {/* Отображаем только имя города */}
                        </div>
                    ))}
                </div>
            )}
            {error && <div className="validate-error text-red-500">{error}</div>}
        </div>
    );
});

export const ForwardRefInput = React.forwardRef(({ type, name, required, className, value, onChange, placeholder, icon, error, label, ...props }, ref) => {
    const [suggestions, setSuggestions] = useState([]);
    const [inputValue, setInputValue] = useState(value);
    const [openSuggestions, setOpenSuggestions] = useState(false);

    const objRef = useRef();

    const debouncedFetchSuggestions = useRef(
        debounce(async (query) => {
            try {
                if (query && query.length >= 3) { // Например, делаем запросы только если длина строки больше 3 символов
                    const data = await getSuggestions(query);
                    setSuggestions(data?.suggestions || []);
                } else {
                    setSuggestions([]);
                }
            } catch (e) {
                console.log(e);
            }
        }, 300)
    ).current;

    useEffect(() => {
        // Обрабатываем изменения inputValue и запускаем дебаунс запрос
        debouncedFetchSuggestions(inputValue);
    }, [inputValue, debouncedFetchSuggestions]);

    const handleChange = (value) => {
        setInputValue(value || ''); // Убедимся, что value не null
        onChange(value); // вызов onChange для обновления родительского состояния
    };

    const handleSuggestionClick = (suggestion) => {
        const selectedValue = `${suggestion.make} ${suggestion.model}`;
        setInputValue(selectedValue);
        onChange(selectedValue);
        setSuggestions([]); // Скрываем подсказки после выбора
    };

    // hide suggestions when clicked outside
    useEffect(() => {
        const handleClickOutside = (e) => {
            if (objRef.current && !objRef.current.contains(e.target)) {
                setOpenSuggestions(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [objRef]);

    useEffect(() => {
        setInputValue(value || ''); // Синхронизируем inputValue с внешним value
    }, [value]); // Этот useEffect будет срабатывать при изменении value из props


    const onBlurred = () => {
        setTimeout(() => {
            setOpenSuggestions(false);
        }, 200);
    };

    return (
        <div className='flex flex-col gap-1 w-full relative' ref={objRef}>
            {label && (
                <label>
                    {label}
                    {required && <span className="text-red-500">*</span>}
                </label>
            )}
            <InputGroup inside className="w-full">
                {icon && <InputGroup.Addon>{icon}</InputGroup.Addon>}
                <Input
                    ref={ref}
                    placeholder={placeholder}
                    onChange={handleChange}
                    value={inputValue}
                    onBlur={onBlurred}
                    onFocus={() => setOpenSuggestions(true)}
                    {...props}
                    className="w-full p-2 border border-gray-300 rounded outline-none tg_text tg_bg_secondary text-base"
                />
            </InputGroup>
            {suggestions.length > 0 && openSuggestions && (
                <div className="suggestions-list tg_bg_secondary tg_text absolute w-full -bottom-[115%] rounded shadow-lg mt-2 z-10 max-h-[300px] overflow-auto">
                    {suggestions.map((suggestion, index) => (
                        <div
                            key={index}
                            className="p-2 cursor-pointer"
                            onClick={() => handleSuggestionClick(suggestion)}
                        >
                            {suggestion.make} {suggestion.model}
                        </div>
                    ))}
                </div>
            )}
            {error && <div className="validate-error text-red-500">{error}</div>}
        </div>
    );

});

export const MyInput = (props) => {
    const { label, required, icon, onChange, value, ...rest } = props;

    return (
        <div className='flex flex-col gap-1 w-full'>
            {label && (
                <label>
                    {label}
                    {required && <span className="text-red-500">*</span>}
                </label>
            )}
            <InputGroup inside className="w-full">
                {icon && <InputGroup.Addon>{icon}</InputGroup.Addon>}
                <Input onChange={onChange} value={value} {...rest} className="w-full p-2 border border-gray-300 rounded outline-none tg_text tg_bg_secondary text-base" />
            </InputGroup>
            {rest.error && <div className="validate-error text-red-500">{rest.error}</div>}
        </div>
    );
};

export const Textarea = (props) => {
    return <div>
        <textarea {...props} style={{
            fieldSizing: 'content',
            minHeight: '6lh'
        }} className="w-full p-2 tg_text border border-gray-300 rounded outline-none 
            tg_text tg_bg_secondary" />
        {props.error && <div className="validate-error text-red-500">{props.error}</div>}
    </div>
}

export const PriceInput = ({ value, setPrice, currency, setCurrency, isNegotiable, setIsNegotiable, currencyList = ['USD', 'EUR', 'UAH'], ...rest }) => {
    return (
        <div className="flex flex-wrap gap-2 tg_text">
            <div className="flex items-center">
                <InputGroup inside className="w-full">
                    <InputGroup.Addon>
                        <select
                            className="border-r border-gray-300 tg_text tg_bg_secondary outline-none text-base"
                            value={currency}
                            onChange={(e) => setCurrency(e.target.value)}
                        >
                            {currencyList.map((currency) => (
                                <option key={currency} value={currency}>
                                    {currency}
                                </option>
                            ))}
                        </select>
                    </InputGroup.Addon>
                    <Input
                        onChange={setPrice}
                        value={value}
                        name="price"
                        className='priceInput h-[40px] border-r-0 text-base'
                        type="number"
                        id="price"
                        placeholder="Ваша ціна"
                    />
                </InputGroup>

            </div>
            <div className="flex items-center gap-2">
                <input
                    type="checkbox"
                    id="negotiable"
                    name="negotiable"
                    checked={isNegotiable}
                    onChange={(e) => setIsNegotiable(e.target.checked)}
                    className="w-4 h-4 border-gray-300 rounded tg_text tg_bg_secondary text-base"
                />
                <label htmlFor="negotiable" className="tg_text dark:text-gray-100">
                    Торг?
                </label>
            </div>

            {rest.error && <div className="validate-error basis-full text-red-500">{rest.error}</div>}
        </div>
    );
};

export const AutoCompleteInput = ({
    label,
    data,
    value,
    onChange,
    getDisplayValue = (item) => item.toString(),
    getCyrillicValue = null,
    ...rest
}) => {
    // Подготовка данных для React-Select
    const options = data.map(item => ({
        value: getDisplayValue(item),
        label: getDisplayValue(item),
        cyrillicValue: getCyrillicValue ? getCyrillicValue(item) : '',
    }));
    const handleChange = (selectedOption) => {
        const selected = options.find(option => option.value === selectedOption.value);

        if (selected) {
            onChange({
                value: selected.value,
                cyrillicValue: selected.cyrillicValue,
            });
        } else {
            onChange({
                value: selectedOption.value,
                cyrillicValue: null,
            });
        }
    };

    const customStyles = {
        control: (provided) => ({
            ...provided,
            minHeight: '40px',
        }),
        menu: (provided) => ({
            ...provided,
            maxHeight: '150px',
        }),
    };

    const filterOption = ({ label, data }, inputValue) => {
        const searchValue = inputValue.toLowerCase();
        const labelStr = String(label).toLowerCase(); // Приводим label к строке
        const cyrillicValueStr = data.cyrillicValue ? data.cyrillicValue.toLowerCase() : '';
        return (
            labelStr.includes(searchValue) ||
            cyrillicValueStr.includes(searchValue)
        );
    };

    const formatOptionLabel = ({ value, cyrillicValue }) => (
        <div>
            <div className='tg_text'>{value}</div>
            {cyrillicValue && <div style={{ fontSize: 'small', color: 'gray' }}>{cyrillicValue}</div>}
        </div>
    );

    return (
        <div className="flex flex-col gap-1 tg_text">
            <label>{label}</label>
            <Select
                value={options.find(option => option.value === value)}
                onChange={handleChange}
                classNamePrefix="custom-select" // Префикс для классов элементов
                className='autoComplete h-full min-h-[45px] w-full tg_text rounded outline-none tg_text tg_bg_secondary tg_text z-10'
                options={options}
                styles={customStyles}
                formatOptionLabel={formatOptionLabel}
                placeholder={`${label.toLowerCase()}`}
                filterOption={filterOption} // Добавление кастомной фильтрации
                {...rest}

            />
            {rest.error && <div className="validate-error text-red-500">{rest.error}</div>}
        </div>
    );
};

export const CityAutoCompleteInput = (props) => {
    const { label, endpoint, value, onChange, icon, ...rest } = props;

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleSearch = useCallback(
        debounce((query) => {
            if (query.length >= 2) {
                setLoading(true);
                fetch(`${endpoint}?q=${query}`)
                    .then(response => response.json())
                    .then(data => {
                        if (!data || data.length === 0) {
                            setData([]); // якщо немає даних, то очищаємо список
                            setLoading(false);
                            return;
                        }
                        const formattedData = data.map(item => ({
                            label: item.name,
                            value: item.name,
                            parent: item.parent[0] && item.parent[0].name // добавляем имя родителя
                        }));
                        setData(formattedData);
                        setLoading(false);
                    });
            } else {
                setData([]);
            }
        }, 500),
        []
    );

    useEffect(() => {
        handleSearch(value);
    }, [value]);

    return (
        <div className='flex flex-col gap-1 w-full'>
            <label>
                {label}
            </label>
            <InputGroup inside className="w-full">
                {icon && <InputGroup.Addon>{icon}</InputGroup.Addon>}
                <AutoComplete
                    className="w-full autoComplete h-full min-h-[40px] flex w-full outline-none tg_text tg_bg_secondary text-base"
                    data={data}
                    value={value}
                    onChange={onChange}
                    placeholder={`Оберіть ${label.toLowerCase()}`}
                    style={{ width: '100%' }}

                    renderMenuItem={(item, obj) => {
                        return (
                            <div key={item}>
                                <div className="">{item}</div>
                                {obj.parent && <div className="text-xs">{obj.parent}</div>}
                            </div>
                        );
                    }}
                />
                {loading && (
                    <InputGroup.Addon>
                        <Loader />
                    </InputGroup.Addon>
                )}
            </InputGroup>
            {rest.error && <div className="validate-error text-red-500">{rest.error}</div>}
        </div>
    );
};

export const SelectInput = ({ label, data, value, onChange, icon, ...rest }) => {
    return (
        <div className="flex flex-col gap-1 tg_text w-full">
            <label className='text-base'>{label}</label>
            <InputGroup inside className="w-full h-full items-stretch">
                {icon && <InputGroup.Addon className="flex items-center">{icon}</InputGroup.Addon>}
                <SelectPicker
                    {...rest}
                    className="h-full min-h-[40px] flex w-full tg_text tg_bg_secondary border-0 text-base"
                    data={data}
                    value={value}
                    onChange={onChange}
                    placeholder={label}
                    cleanable={false}
                    placement="auto" // Попробуйте установить auto для автоматического определения направления
                />
            </InputGroup>
            {/* Formik error */}
            {rest.error && (
                <div className="text-red-500">{rest.error}</div>
            )}
        </div>
    );
}

export const PhotoUploader = ({ files, setFiles, ...rest }) => {
    // Проверяем, является ли устройство мобильным
    const isMobileDevice = /Mobi|Android/i.test(window.navigator.userAgent);

    const onDrop = useCallback((acceptedFiles) => {
        const newFiles = acceptedFiles.map((file) => Object.assign(file, {
            preview: URL.createObjectURL(file)
        }));

        // Используем `formik.setFieldValue` для обновления поля `files`
        setFiles("files", [...files, ...newFiles]);
    }, [setFiles, files]);

    // Удаление файла
    const removeFile = (file) => () => {
        setFiles("files", files.filter((f) => f !== file));
        URL.revokeObjectURL(file.preview); // Очищаем URL для освобождения памяти
    };

    // Настройки для dropzone
    const { getRootProps, open, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'image/jpeg': [],
            'image/png': [],
            'image/webp': [],
            'image/heic': [],
            'image/jfif': [],
        },
        multiple: !isMobileDevice, // Запрещаем множественный выбор на мобильных устройствах
        maxFiles: 30, // Максимальное количество файлов
    });

    // Отображение превью для загруженных изображений
    const thumbs = files.map((file) => (
        <div key={file.name} className="thumb">
            <div className="thumb-inner">
                <PhotoProvider>
                    <PhotoView src={file.preview}>
                        <img src={file.preview} className="img" alt={file.name} />
                    </PhotoView>
                </PhotoProvider>
                <button className="remove-btn" onClick={removeFile(file)} aria-label={`Remove ${file.name}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg>
                </button>
            </div>
        </div>
    ));

    return (
        <div className="container">
            <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <p className='tg_text'>
                    Перенесіть фото сюди або натисніть щоб обрати (до 30 фото) або натисніть кнопку нижче
                    <br />
                    <small>перша фото зі списку буде відображатись у списку оголошень</small>
                </p>
                <button type="button" className="block bg-blue-500 text-white px-4 py-2 rounded mt-4 mx-auto">
                    Обрати файли
                </button>
            </div>
            <aside className="thumbs-container">
                {thumbs}
            </aside>
            {rest.error && <div className="validate-error text-red-500">{rest.error}</div>}
        </div>
    );
};


