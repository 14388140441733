import React from "react";
import { FaSearch } from 'react-icons/fa';
import { ForwardRefInput } from "../../Form/Fields";
import useCarStore from '@/Store/useCarStore';

const SearchFilter = ({ searchInputRef }) => {
    const tempSearchQuery = useCarStore((state) => state.tempSearchQuery);
    const changeTempSearchQuery = useCarStore((state) => state.changeTempSearchQuery);

    return (
        <ForwardRefInput
            type="search"
            name="search"
            className="w-full p-2 border border-gray-300 rounded outline-none focus:border-blue-500"
            value={tempSearchQuery}
            onChange={changeTempSearchQuery}
            placeholder="Porsche Macan"
            icon={<FaSearch className="text-base" />}
            error={null}
            ref={searchInputRef} // Добавляем ref для автофокуса
        />
    );
};

export default SearchFilter;