import React from "react";
import useCarStore from '@/Store/useCarStore';
import { CitySearchInput } from "@/Components/Form/Fields";
import { FaSearch } from 'react-icons/fa';

const CityFilter = React.memo(() => {
    const tempFilters = useCarStore((state) => state.tempFilters);
    const setTempFilters = useCarStore((state) => state.setTempFilters);
    const cityInputRef = React.useRef(null);
    const cleanCityName = (cityName) => {
        return cityName.replace(/^[^.]*\. /, "");
    };

    const handleChange = (value) => {
        const cityName = cleanCityName(value);
        setTempFilters({ location: cityName });
    };

    const handlePopularClick = (value) => {
        setTempFilters({ location: value });
    };

    const mostPopularCities = ["Київ", "Львів", "Одеса", "Харків", "Дніпро"];

    return (
        <div className="flex gap-1 flex-col w-full mb-4">
            {/* add title */}
            <div className="text-base w-full tg_text flex items-baseline justify-between">
                <span>Місто</span>
            </div>

            <div className="flex items-baseline gap-1 flex-1">
                <CitySearchInput
                    type="search"
                    name="location"
                    className="w-full p-2 border border-gray-300 rounded outline-none focus:border-blue-500"
                    value={tempFilters.location}
                    onChange={handleChange}
                    placeholder="Почніть вводити назву міста"
                    icon={<FaSearch className="text-base" />}
                    error={null}
                    ref={cityInputRef}
                />
            </div>

            <div className="flex gap-2 tg_link justify-between">
                {mostPopularCities.map((range, index) => (
                    <button
                        key={index}
                        className="p-2"
                        onClick={() => handlePopularClick(range)}
                    >
                        {range}
                    </button>
                ))}
            </div>

        </div>
    );

});

export default CityFilter;