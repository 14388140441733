import React from "react";
import { useState, useEffect } from "react";
import { MyRangeSlider, ExponentialSlider } from "@/Components/Buttons/RangeSlider";
import useCarStore from '@/Store/useCarStore';

const PriceSlider = () => {
    const tempFilters = useCarStore((state) => state.tempFilters);
    const setTempFilters = useCarStore((state) => state.setTempFilters);

    const [sliderValue, setSliderValue] = useState([
        tempFilters.price_min || 0,
        tempFilters.price_max || 150000,
    ]);


    const popularRanges = [
        { min: 0, max: 5000, label: "0 - 5000" },
        { min: 8000, max: 12000, label: "8000 - 12k" },
        { min: 18000, max: 26000, label: "18k - 26k" },
        { min: 26000, max: 150000, label: "26k+" },
    ];

    // Позначки (відображення реальних значень)
    const marks = {
        0: "0",
        10: "8k",
        70: "30k",
        90: "80k",
        100: "80k+",
    };

    
    // Логічні межі
    const ranges = [
        { min: 0, max: 8000, scale: 10 }, // 10% шкали
        { min: 8000, max: 30000, scale: 60 }, // 60% шкали
        { min: 30000, max: 80000, scale: 20 }, // 20% шкали
        { min: 80000, max: 100000, scale: 10 }, // 10% шкали
    ];

    // Відсоткові межі
    const percentRanges = [0, 10, 70, 90, 100];

    const handleSliderComplete = (value) => {
        setTempFilters({ price_min: value[0] });
        setTempFilters({ price_max: value[1] });
        setSliderValue(value);
    };


    const handleInputChange = (e, field) => {
        let value = e.target.value === "" ? "" : Number(e.target.value);

        // Забороняємо значення менші за 0
        if (value < 0) value = 0;

        if (field === "price_min") {
            // Мінімальне значення не може бути більшим за максимальне
            if (value > tempFilters.price_max) {
                setTempFilters({ price_max: value });
            }
            setTempFilters(field, value);
        } else if (field === "price_max") {
            // Максимальне значення не може бути меншим за мінімальне
            if (value < tempFilters.price_min) {
                setTempFilters({ price_min: value });
            }
            setTempFilters({ [field]: value });
        }
    };


    const handleRangeClick = (range) => {
        setTempFilters({ price_min: range.min });
        setTempFilters({ price_max: range.max });
    };

    // Оновлюємо значення слайдера при зміні значень у Formik
    useEffect(() => {
        setSliderValue([tempFilters.price_min, tempFilters.price_max]);
    }, [tempFilters.price_min, tempFilters.price_max]);

    return (
        <div className="flex flex-col gap-4">
            {/* Заголовок */}
            <div className="text-md tg_text flex justify-between items-center">
                <span>Ціна</span>
                <div className="text-sm tg_hint flex items-center gap-2">
                    {/* Інпут для мінімальної ціни */}
                    <input
                        min={0}
                        step={100}
                        type="number"
                        name="price_min"
                        className="border-b border-gray-400 focus:border-blue-500 outline-none w-16 text-center bg-transparent"
                        value={Number(tempFilters.price_min)}
                        onChange={(e) => handleInputChange(e, "price_min")}
                        placeholder="Мін"
                    />
                    <span>$</span>
                    <span>-</span>
                    {/* Інпут для максимальної ціни */}
                    <input
                        type="number"
                        step={100}
                        name="price_max"
                        className="border-b border-gray-400 focus:border-blue-500 outline-none w-16 text-center bg-transparent"
                        value={!!tempFilters.price_max ? Number(tempFilters.price_max) : 150000}
                        onChange={(e) => handleInputChange(e, "price_max")}
                        placeholder="Макс"
                    />
                    <span>$</span>
                </div>
            </div>

            <ExponentialSlider
                iniValue={sliderValue}
                onChange={handleSliderComplete}
                marks={marks}
                ranges={ranges}
                percentRanges={percentRanges}
            />

            {/* Кнопки популярних діапазонів */}
            <div className="flex gap-2 tg_link justify-between">
                {popularRanges.map((range, index) => (
                    <button
                        key={index}
                        className="p-2"
                        onClick={() => handleRangeClick(range)}
                    >
                        {range.label}
                    </button>
                ))}
            </div>

        </div>
    );
};


export default PriceSlider;