
import React from "react";
import useCarStore from '@/Store/useCarStore';
import { MyRangeSlider } from "@/Components/Buttons/RangeSlider";

const MotorRangeSlider = React.memo(() => {
    const tempFilters = useCarStore((state) => state.tempFilters);
    const setTempFilters = useCarStore((state) => state.setTempFilters);

    const handleChange = ([min, max]) => {
        setTempFilters({ motor_min: min, motor_max: max });
    };

    const marks = {
        0: "0",
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
        6: "6",
    };
    return (<div className="mb-4">
        <MyRangeSlider
            label="Об'єм двигуна"
            min={0}
            max={6}
            step={0.1}
            unit=" л"
            value={[tempFilters.motor_min || 0, tempFilters.motor_max || 6]}
            onChange={handleChange}
            marks={marks}
        />
    </div>
    );
});

export default MotorRangeSlider;