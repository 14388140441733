import React, { useEffect, useCallback, useState } from "react";
import { useFormik } from "formik";
import {
    MyInput,
    Textarea,
    PriceInput,
    AutoCompleteInput,
    CityAutoCompleteInput,
    SelectInput,
    PhotoUploader,
} from "../Components/Form/Fields";
import debounce from "lodash.debounce";
import InputMask from "react-input-mask";
import BackBtn from "../Components/Layout/BackBtn";
import { SlSpeedometer } from "react-icons/sl";
import { IoLocationOutline } from "react-icons/io5";
import { PiEngineLight } from "react-icons/pi";
import { TbManualGearbox } from "react-icons/tb";
import { FaOilCan } from "react-icons/fa";
import { ReactComponent as CarChassis } from "@/Utils/CarChassis.svg";
import { validationSchema } from "@/Utils/validationSchema";
import { createCarAd } from "@/Services/api";
import { useNavigate } from "react-router-dom";
import { fuelList, gearboxList, automotiveDriveList } from "@/Utils/optionsTypes";
import { SmallSpiner } from "@/Components/Layout/LoadingSpinner";
import { useTelegram } from "@/Hooks/useTelegram";
import { cleanPhoneNumber } from "@/Utils/cleanPhoneNumber";



const CreateAnnouncement = () => {
    const { tg } = useTelegram();
    const [loading, setLoading] = useState(false);
    const [makes, setMakes] = useState([]);
    const [models, setModels] = useState([]);
    const [years, setYears] = useState([]);
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            price: 0.0, // 0.0
            currency: "USD",
            isNegotiable: false,
            selectedMake: "",
            selectedMakeCirilic: "",
            selectedModel: "",
            selectedModelCirilic: "",
            selectedYear: "",
            selectedCity: "",
            selectedFuel: "",
            selectedGearbox: "",
            files: [],
            motor: 0.0,
            mileage: 0,
            driveType: "",
            description: "",
            vincode: "",
            carNumber: "",
            phone: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            console.log("Try submit", formik.submitCount, formik.errors);
            if (formik.submitCount > 0 && Object.keys(formik.errors).length > 0) {
                // Валідація не пройшла
                tg?.HapticFeedback?.notificationOccurred('error'); // Telegram хаптичний відгук
            }

            setLoading(true);

            try {
                const formData = new FormData();

                values.files.forEach((file) => {
                    formData.append("photos", file);
                });

                formData.append("carAdData", JSON.stringify({
                    price: Number(values.price),
                    discounted_price: Number(values.price),
                    vincode: values.vincode,
                    currency: values.currency,
                    is_negotiable: Boolean(values.isNegotiable),
                    make: values.selectedMake,
                    model: values.selectedModel,
                    car_year: Number(values.selectedYear),
                    gearbox: values.selectedGearbox,
                    fuel: values.selectedFuel,
                    motor: Number(values.motor),
                    mileage: Number(values.mileage),
                    drive_type: values.driveType,
                    description: values.description,
                    location: values.selectedCity,
                    car_number: values.carNumber.replace(/\s/g, ''),
                    cyrillic_search: `${values.selectedMakeCirilic} ${values.selectedModelCirilic}`,
                    phone: cleanPhoneNumber(values.phone),
                }));

                const res = await createCarAd(formData)

                if (res.status >= 200 && res.status < 300) {
                    formik.resetForm();
                    tg?.HapticFeedback?.notificationOccurred('success');
                    // redirect to profile
                    navigate("/profile");
                } else {
                    tg?.HapticFeedback?.notificationOccurred('error');
                    return
                }
                // reset form
            } catch (error) {
                console.log(error)
                tg?.HapticFeedback?.notificationOccurred('error');
            } finally {
                setLoading(false);
            }
        },
    });

    const debouncedFetchMakes = useCallback(
        debounce(() => {
            fetch("/api/makes")
                .then((response) => response.json())
                .then((data) => setMakes(data));
        }, 300),
        []
    );

    const debouncedFetchModels = useCallback(
        debounce((make) => {
            fetch(`/api/models?make=${make}`)
                .then((response) => response.json())
                .then((data) => setModels(data));
        }, 300),
        []
    );

    const debouncedFetchYears = useCallback(
        debounce((make, model) => {
            fetch(`/api/years?make=${make}&model=${model}`)
                .then((response) => response.json())
                .then((data) => setYears(data));
        }, 300),
        []
    );

    const handleFocus = (e) => {
        setTimeout(() => {
            e.target.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
        }, 300);  // Небольшая задержка для корректной работы на всех устройствах
    };

    useEffect(() => {
        debouncedFetchMakes();
    }, [debouncedFetchMakes]);

    useEffect(() => {
        if (formik.values.selectedMake) {
            debouncedFetchModels(formik.values.selectedMake);
        } else {
            setModels([]);
            formik.setFieldValue("selectedModel", "");
            formik.setFieldValue("selectedModelCirilic", "");
            setYears([]);
            formik.setFieldValue("selectedYear", "");
        }
    }, [formik.values.selectedMake, debouncedFetchModels]);

    useEffect(() => {
        if (formik.values.selectedModel) {
            debouncedFetchYears(formik.values.selectedMake, formik.values.selectedModel);
        } else {
            formik.setFieldValue("years", []);
            formik.setFieldValue("selectedYear", "");
        }
    }, [formik.values.selectedModel, formik.values.selectedMake, debouncedFetchYears]);

    return (
        <div className="min-h-screen py-4 text-base">
            <BackBtn route={"/profile"} />
            <h1 className="text-2xl font-bold tg_text mb-4 p-2">Створити оголошення</h1>

            {/* Переміщення компоненту PhotoUploader на верхню частину сторінки */}
            <div className="mb-4">
                <PhotoUploader
                    files={formik.values.files}
                    setFiles={formik.setFieldValue}
                    error={formik.touched.files && formik.errors.files}
                />
            </div>

            <form onSubmit={formik.handleSubmit} className="flex gap-4 p-2 flex-col">
                <div className="tg_section_bg rounded-lg p-2">
                    <div className="flex justify-between flex-wrap mb-2 gap-2 ">
                        <div className="flex flex-col gap-2 w-full z-10">
                            <AutoCompleteInput
                                label="Марка"
                                name="selectedMake"
                                data={makes}
                                getDisplayValue={(item) => item.Make}
                                getCyrillicValue={(item) => item.MakeCirilic}
                                value={formik.values.selectedMake}
                                onChange={(objValue) => {
                                    formik.setFieldValue("selectedMake", objValue.value);
                                    formik.setFieldValue("selectedMakeCirilic", objValue.cyrillicValue);
                                }}
                                error={formik.touched.selectedMake && formik.errors.selectedMake}
                            />
                        </div>
                        <div className="flex-1 flex-col gap-2 w-1/2">
                            <AutoCompleteInput
                                label="Модель"
                                name="selectedModel"
                                data={models}
                                value={formik.values.selectedModel}
                                onChange={(objValue) => {
                                    formik.setFieldValue("selectedModel", objValue.value);
                                    formik.setFieldValue("selectedModelCirilic", objValue.cyrillicValue);
                                }}
                                getDisplayValue={(item) => item.Model}
                                getCyrillicValue={(item) => item.ModelCirilic}
                                disabled={!formik.values.selectedMake}
                                error={formik.touched.selectedModel && formik.errors.selectedModel}
                            />
                        </div>
                        <div className="flex-1 flex-col gap-2 w-1/2">
                            <AutoCompleteInput
                                label="Рік"
                                name="selectedYear"
                                data={years}
                                getDisplayValue={(item) => item}
                                value={formik.values.selectedYear}
                                onChange={(objValue) => formik.setFieldValue("selectedYear", objValue.value)}
                                disabled={!formik.values.selectedModel || !formik.values.selectedMake}
                                placeholder="Рік"
                                error={formik.touched.selectedYear && formik.errors.selectedYear}
                            />
                        </div>
                    </div>
                    <div className="flex items-start gap-2 py-2">
                        <PriceInput
                            name="price"
                            currency={formik.values.currency}
                            setCurrency={(value) => formik.setFieldValue("currency", value)}
                            isNegotiable={formik.values.isNegotiable}
                            setIsNegotiable={(value) => formik.setFieldValue("isNegotiable", value)}
                            value={formik.values.price}
                            setPrice={(value) => formik.setFieldValue("price", value)}
                            error={formik.touched.price && formik.errors.price}
                        />
                    </div>
                    <div className="grid grid-cols-2 tg_text text-base gap-y-4 dark:gap-y-4 py-2 gap-1">
                        <div className="flex items-start gap-2">
                            <MyInput
                                {...formik}
                                name="mileage"
                                className="w-full p-2 border border-gray-300 rounded outline-none focus:border-blue-500"
                                value={formik.values.mileage}
                                onChange={(value) => formik.setFieldValue("mileage", value)}
                                placeholder="Пробіг (тис. км)"
                                label="Пробіг (тис. км)"
                                icon={<SlSpeedometer className="text-xl" />}
                                error={formik.touched.mileage && formik.errors.mileage}
                            />
                        </div>
                        <div className="flex items-start gap-2">
                            <CityAutoCompleteInput
                                name="selectedCity"
                                label="Місцезнаходження"
                                endpoint="/api/autocomplete-cities"
                                value={formik.values.selectedCity}
                                onChange={(value) => formik.setFieldValue("selectedCity", value)}
                                icon={<IoLocationOutline className="text-xl" />}
                                error={formik.touched.selectedCity && formik.errors.selectedCity}
                            />
                        </div>
                        <div className="flex items-start gap-2">
                            <SelectInput
                                id="fuel"
                                name="fuel"
                                data={fuelList}
                                value={formik.values.selectedFuel}
                                onChange={(value) => formik.setFieldValue("selectedFuel", value)}
                                label={"Тип палива"}
                                icon={<FaOilCan className="text-xl" />}
                                error={formik.touched.selectedFuel && formik.errors.selectedFuel}
                            />
                        </div>
                        <div className="flex items-start gap-2">
                            <SelectInput
                                id="gearbox"
                                name="gearbox"
                                data={gearboxList}
                                value={formik.values.selectedGearbox}
                                onChange={(value) => formik.setFieldValue("selectedGearbox", value)}
                                label={"Коробка передач"}
                                icon={<TbManualGearbox className="text-xl" />}
                                error={formik.touched.selectedGearbox && formik.errors.selectedGearbox}
                            />
                        </div>
                        <div className="flex items-start gap-2">
                            <MyInput
                                className="w-full p-2 border border-gray-300 rounded outline-none focus:border-blue-500"
                                type="number"
                                name="motor"
                                value={formik.values.motor}
                                onChange={(value) => formik.setFieldValue("motor", value)}
                                placeholder="Об'єм двигуна"
                                label="Об'єм двигуна"
                                icon={<PiEngineLight className="text-xl" />}
                                error={formik.touched.motor && formik.errors.motor}
                            />
                        </div>
                        <div className="flex items-start gap-2">
                            <SelectInput
                                id="driveType"
                                name="driveType"
                                data={automotiveDriveList}
                                value={formik.values.driveType}
                                onChange={(value) => formik.setFieldValue("driveType", value)}
                                label={"Привід"}
                                icon={<CarChassis className="w-[20xp] h-[20px]" />}
                                error={formik.touched.driveType && formik.errors.driveType}
                            />
                        </div>
                    </div>
                    <div className="flex flex-row justify-between items-center mt-4 text-base tg_text_hint gap-2 ">
                        <div className="avtonumber relative h-[40px] flex items-center border-2 border-blue-500 rounded-md dark:border-blue-800 basis-1/2">
                            <span className="bg-blue-500 px-1 dark:bg-blue-800 text-white min-h-[38px] flex items-center">
                                UA
                            </span>
                            <MyInput
                                className=" w-full p-2 border border-gray-300 rounded outline-none focus:border-blue-500"
                                type="text"
                                name="carNumber"
                                value={formik.values.carNumber}
                                onChange={(value) => formik.setFieldValue("carNumber", value)}
                                placeholder="Номер автомобіля"
                                error={formik.touched.carNumber && formik.errors.carNumber}
                            />
                        </div>
                        <div className="avtonumber relative flex items-center border-2 border-blue-500 rounded-md dark:border-blue-800 basis-1/2">
                            <MyInput
                                className="w-full p-2 border border-gray-300 rounded outline-none focus:border-blue-500"
                                type="text"
                                name="vincode"
                                value={formik.values.vincode}
                                onChange={(value) => formik.setFieldValue("vincode", value)}
                                placeholder="Вінкод"
                                error={formik.touched.vincode && formik.errors.vincode}
                            />
                        </div>
                    </div>
                    <div className="flex items-start gap-2 mt-4">
                        <InputMask
                            mask="+38 (999) 999 99 99"
                            name="phone"
                            value={formik.values.phone}
                            onChange={(e) => formik.setFieldValue("phone", e.target.value)}
                            onBlur={formik.handleBlur}
                            inputmode="numeric"
                            onFocus={handleFocus}
                        >
                            {(inputProps) => (
                                <div className="w-full">
                                    <label className="text-base tg_text">Телефон</label>
                                    <input
                                        type="text"
                                        inputmode="numeric"
                                        {...inputProps}
                                        className="w-full p-2 border rounded outline-none focus:border-blue-500 className=w-full p-2 border border-gray-300 rounded outline-none tg_text tg_bg_secondary"
                                        placeholder="Телефон"
                                    />
                                    {formik.touched.phone && formik.errors.phone && (
                                        <span className="text-red-500">{formik.errors.phone}</span>
                                    )}
                                </div>
                            )}
                        </InputMask>
                    </div>
                    <div className="mt-2 text-left tg_text_hint text-base">
                        <p>Дата публікації: {new Date().toLocaleDateString()}</p>
                    </div>
                </div>
                <div className="tg_section_bg rounded-lg tg_text p-2 pt-0">
                    <h3 className="text-lg font-semibold">Опис</h3>
                    <Textarea
                        name="description"
                        className="w-full p-2 border border-gray-300 rounded outline-none focus:border-blue-500"
                        value={formik.values.description}
                        onChange={(e) => formik.setFieldValue("description", e.target.value)}
                        placeholder="Опис автомобіля. Роскажіть максимально детально про ваше авто, які є плюси та мінуси. Чи були ДТП, які роботи проводились, тощо."
                        error={formik.touched.description && formik.errors.description}
                        onFocus={handleFocus}
                    />
                </div>
                {/* <button type={loading ?
                    "button" : "submit"
                } className={`flex items-center gap-2 justify-center uppercase font-semibold mt-12 text-white py-2 px-4 rounded ${loading ? 'tg_bg_secondary' : 'bg-blue-500'}`}>
                    Створити {loading && <SmallSpiner color={"white"} />}
                </button> */}
                <ConditionalSubmitButton loading={loading} onClick={formik.handleSubmit} />
            </form>
        </div>
    );
};

// components/ConditionalSubmitButton.js
export const ConditionalSubmitButton = ({ loading, onClick, textLabel = {
    main: "Створити",
    loading: "Створюється...",
} }) => {
    const { isTelegram, tg } = useTelegram();
    useEffect(() => {
        if (isTelegram()) {
            const { MainButton } = tg;

            // Встановлюємо текст кнопки в залежності від стану завантаження
            MainButton.text = loading ? textLabel.loading : textLabel.main;
            // Показуємо кнопку тільки якщо не завантажується
            if (!loading) {
                MainButton.show();
            } else {
                MainButton.hide();
            }

            // Встановлюємо обробник події
            MainButton.offClick(); // Видаляємо попередні обробники
            MainButton.onClick(onClick);

            // Очистка при розмонтуванні компонента
            return () => {
                MainButton.offClick(onClick);
                MainButton.hide();
            };
        }
    }, [loading, onClick]);


    if (isTelegram()) {
        return null; // Telegram кнопка керується API, не рендеримо нічого
    }

    // Якщо не в Telegram, рендеримо звичайну кнопку
    return (
        <button
            type={loading ? "button" : "submit"}
            onClick={onClick}
            disabled={loading}
            className={`flex items-center gap-2 justify-center uppercase font-semibold mt-12 text-white py-2 px-4 rounded ${loading ? 'tg_bg_secondary' : 'bg-blue-500'} text-base`}
        >
            {textLabel.main} {loading && <SmallSpiner color={"white"} />}
        </button>
    );
};



export default CreateAnnouncement;

