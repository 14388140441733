import React, { useState, useEffect } from "react";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { useTelegram } from "@/Hooks/useTelegram";
import useCarStore from '@/Store/useCarStore';
import YearRangeSlider from "./filter/YearRangeSlider";
import CityFilter from "./filter/CityFilter";
import PriceSlider from "./filter/PriceSlider";
import MotorRangeSlider from "./filter/MotorFilter";
import MileageFilter from "./filter/MileageFilter";
import DriverTypeFilter from "./filter/DriveTypeFilter";
import FuleFilter from "./filter/FuelFilter";
import GearboxFilter from "./filter/GearboxFilter";
import SearchFilter from "./filter/SearchFilter";


const TelegramMainButton = React.memo(({ text, toggleDrawer }) => {
    const { tg } = useTelegram();
    const applyFilters = useCarStore((state) => state.applyFilters);

    const handleClick = () => {
        applyFilters();
        toggleDrawer();
    };

    useEffect(() => {
        if (tg) {
            tg.MainButton.text = text;
            tg.MainButton.show();
            tg.MainButton.onClick(handleClick);

            // Очистка при розмонтуванні компонента
            return () => {
                tg.MainButton.offClick(handleClick);
                tg.MainButton.hide();
            };
        }
    }, [text]);

    // if localhost display btn
    if (window.location.hostname === "localhost") {
        return (
            <button onClick={handleClick} className="tg_btn_color tg_btn_text tg_btn p-2 rounded w-full">
                {text}
            </button>
        );
    }

    return null; // Цей компонент не рендерить нічого в DOM



});
const TelegramSecondaryButton = React.memo(({ text, toggleDrawer }) => {
    const { tg } = useTelegram();
    const resetFilters = useCarStore((state) => state.resetFilters);

    const handleClick = () => {
        resetFilters();
        toggleDrawer();
    };

    useEffect(() => {
        if (window.Telegram && tg) {
            const { SecondaryButton } = tg;

            SecondaryButton.text = text;
            SecondaryButton.show();



            SecondaryButton.onClick(handleClick);

            // Очистка при розмонтуванні компонента
            return () => {
                SecondaryButton.offClick(handleClick);
                SecondaryButton.hide();
            };
        }
    }, [text]);

    // if localhost display btn
    if (window.location.hostname === "localhost") {
        return (
            <button onClick={handleClick} className="tg_btn_color tg_btn_text tg_btn p-2 rounded w-full">
                {text}
            </button>
        );
    }

    return null; // Цей компонент не рендерить нічого в DOM
});

const TelegramBackButton = React.memo(({ onClose }) => {
    const { tg } = useTelegram();

    useEffect(() => {
        if (tg) {
            const { BackButton } = tg;

            BackButton.show();

            const handleClick = () => {
                onClose();
            };

            BackButton.onClick(handleClick);

            // Очистка при розмонтуванні компонента
            return () => {
                BackButton.offClick(handleClick);
                BackButton.hide();
            };
        }
    }, [onClose]);

    return null; // Цей компонент не рендерить нічого в DOM
});


const HeaderFilter = React.memo(({ toggleDrawer, isDrawerOpen, searchInputRef }) => {
    const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);

    return (
        <Drawer
            open={isDrawerOpen}
            onClose={toggleDrawer}
            direction="right"
            className="drawer-class"
        >
            <div className="drawer-content flex flex-col h-screen">
                {/* header */}
                <div className="header flex items-baseline justify-between">
                    <div className="text-base py-2 tg_text">
                        Пошук та фільтрація
                    </div>
                    <div className=" ">
                        <button onClick={toggleDrawer} className=" red_text tg_btn p-2 rounded"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg>
                        </button>
                    </div>
                </div>
                <hr className="mb-2" />


                <div className="flex flex-1 flex-col gap-2 overflow-auto mb-4">

                    <SearchFilter searchInputRef={searchInputRef} />
                    <YearRangeSlider />
                    <CityFilter />
                    <PriceSlider />

                    <button
                        onClick={() => setIsAdvancedOpen(!isAdvancedOpen)}
                        className="mt-2 tg_link underline flex items-center self-center justify-cennter gap-2"
                    >
                        Розширений пошук
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={
                            `size-4 transition-transform transform ${isAdvancedOpen ? 'rotate-180' : ''}`
                        }>
                            <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                        </svg>

                    </button>
                    {isAdvancedOpen && (<div className="mt-4">
                        <MotorRangeSlider />

                        <MileageFilter />

                        <DriverTypeFilter />

                        <FuleFilter />

                        <GearboxFilter />
                    </div>)}
                </div>
                <>
                    {isDrawerOpen && (
                        <>
                            <TelegramMainButton text="Шукати" toggleDrawer={toggleDrawer} />
                            <TelegramSecondaryButton text="Очистити" toggleDrawer={toggleDrawer} />
                            <TelegramBackButton onClose={toggleDrawer} />
                        </>
                    )}
                </>

            </div >
        </Drawer >
    );

}, (prevProps, nextProps) =>
    prevProps.isDrawerOpen === nextProps.isDrawerOpen &&
    prevProps.toggleDrawer === nextProps.toggleDrawer
);

export default HeaderFilter;