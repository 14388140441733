import React from "react";
import useCarStore from '@/Store/useCarStore';
import { fuelList } from "@/Utils/optionsTypes";
import { SelectInput } from "../../Form/Fields";

const FuleFilter = () => {
    const tempFilters = useCarStore((state) => state.tempFilters);
    const setTempFilters = useCarStore((state) => state.setTempFilters);

    const handleChange = (val) => {
        setTempFilters({ fuel: val });
    };

    return (<div className="flex gap-1 flex-wrap w-full mb-6">

        <div className="flex items-baseline gap-1 flex-1">
            <SelectInput
                name="fuel"
                className="p-2 border border-gray-300 rounded outline-none focus:border-blue-500"
                value={tempFilters.fuel}
                onChange={handleChange}
                options={fuelList}
                data={fuelList}
                label={"Паливо"}
            />
        </div>
    </div>
    );
}

export default FuleFilter;