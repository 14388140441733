const tg = window.Telegram.WebApp;

export const useTelegram = () => {
    const onClose = () => {
        tg.close();
    };

    const onToggleButtons = (buttons) => {
        if (tg.MainButton.isVisible) {
            tg.MainButton.show();
        } else {
            tg.MainButton.hide();
        }
    };

    const isTelegram = () => {
        return window.Telegram && window.Telegram.WebApp;
    };

    return {
        isTelegram,
        tg,
        user: tg.initDataUnsafe.user,
        onClose,
        onToggleButtons
    };
};
