import React from "react";
import useCarStore from '@/Store/useCarStore';
import { automotiveDriveList } from "@/Utils/optionsTypes";
import { SelectInput } from "../../Form/Fields";

const DriverTypeFilter = () => {
    const tempFilters = useCarStore((state) => state.tempFilters);
    const setTempFilters = useCarStore((state) => state.setTempFilters);

    const handleChange = (val) => {
        setTempFilters({ drive_type: val });
    };

    return (<div className="flex gap-1 flex-wrap w-full mb-6">
        <div className="flex items-baseline gap-1 flex-1">
            <SelectInput
                name="drive_type"
                className="p-2 border border-gray-300 rounded outline-none focus:border-blue-500"
                value={tempFilters.drive_type}
                onChange={handleChange}
                options={automotiveDriveList}
                data={automotiveDriveList}
                label={'Тип привода'}
            />
        </div>
    </div>
    );
}

export default DriverTypeFilter;