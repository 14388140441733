import * as Yup from "yup";

export const validationSchema = Yup.object({
    price: Yup.string()
        .matches(/^[1-9]\d*(\.\d+)?$/, "Ціна повинна бути додатнім числом, яке не починається з нуля")
        .required("Ціна обов'язкова"),
    selectedMake: Yup.string().required("Марка обов'язкова"),
    selectedModel: Yup.string().required("Модель обов'язкова"),
    selectedMakeCirilic: Yup.string().required("Марка обов'язкова"),
    selectedModelCirilic: Yup.string().required("Модель обов'язкова"),
    selectedYear: Yup.string().required("Рік обов'язковий"),
    mileage: Yup.string()
        .matches(/^[1-9]\d*$/, "Пробіг повинен бути додатнім числом, яке не починається з нуля")
        .required("Пробіг обов'язковий"),
    motor: Yup.number().min(0.1, "Об'єм двигуна обов'язковий").required("Об'єм двигуна обов'язковий"),
    phone: Yup.string(),
    vincode: Yup.string().required("Вінкод обов'язковий"),
    carNumber: Yup.string().required("Номер обов'язковий"),
    selectedFuel: Yup.string().required("Тип палива обов'язковий"),
    selectedGearbox: Yup.string().required("Коробка передач обов'язкова"),
    driveType: Yup.string().required("Привід обов'язковий"),
    selectedCity: Yup.string().required("Місцезнаходження обов'язкове"),
    description: Yup.string().required("Опис обов'язковий"),
    files: Yup.array().min(1, "Додайте хоча б одне фото").required("Додайте хоча б одне фото"),
})

export const editAdSchema = Yup.object({
    price: Yup.string()
        .matches(/^[1-9]\d*(\.\d+)?$/, "Ціна повинна бути додатнім числом, яке не починається з нуля")
        .required("Ціна обов'язкова"),
    discounted_price: Yup.string()
        .matches(/^[1-9]\d*(\.\d+)?$/, "Ціна повинна бути додатнім числом, яке не починається з нуля")
        .required("Ціна обов'язкова"),
    mileage: Yup.string()
        .matches(/^[1-9]\d*$/, "Пробіг повинен бути додатнім числом, яке не починається з нуля")
        .required("Пробіг обов'язковий"),
    motor: Yup.number().min(0.1, "Об'єм двигуна обов'язковий").required("Об'єм двигуна обов'язковий"),
    phone: Yup.string(),
    vincode: Yup.string().required("Вінкод обов'язковий"),
    car_number: Yup.string().required("Номер обов'язковий"),
    selectedFuel: Yup.string().required("Тип палива обов'язковий"),
    selectedGearbox: Yup.string().required("Коробка передач обов'язкова"),
    driveType: Yup.string().required("Привід обов'язковий"),
    selectedCity: Yup.string().required("Місцезнаходження обов'язкове"),
    description: Yup.string().required("Опис обов'язковий"),
    // files: Yup.array(),
})
export const filterSchema = Yup.object({
    price_min: Yup.number()
        .nullable()
        .min(0, "Ціна не може бути меншою за нуль")
        .test('is-valid-number', 'Ціна повинна бути числом', (value) => !isNaN(value)),
    price_max: Yup.number()
        .nullable()
        .min(0, "Ціна не може бути меншою за нуль")
        .when('price_min', (price_min, schema) => {
            return price_min
                ? schema.min(price_min, 'Макс. ціна не може бути меншою за Мін. ціну')
                : schema;
        })
        .test('is-valid-number', 'Ціна повинна бути числом', (value) => !isNaN(value)),
    car_year_min: Yup.number()
        .nullable()
        .typeError("Рік повинен бути числом")
        .min(1900, "Рік не може бути раніше 1900"),
    car_year_max: Yup.number()
        .nullable()
        .typeError("Рік повинен бути числом")
        .when('car_year_min', (car_year_min, schema) => {
            return car_year_min
                ? schema.min(car_year_min, 'Макс. рік не може бути меншим за Мін. рік')
                : schema;
        }),
    discounted_price_min: Yup.number()
        .typeError("Ціна повинна бути числом")
        .min(0, "Ціна не може бути меншою за нуль")
        .nullable(),
    discounted_price_max: Yup.number()
        .typeError("Ціна повинна бути числом")
        .min(0, "Ціна не може бути меншою за нуль")
        .nullable()
        .when('discounted_price_min', (discounted_price_min, schema) => {
            return discounted_price_min
                ? schema.min(discounted_price_min, 'Макс. ціна не може бути меншою за Мін. ціну')
                : schema;
        }),

    motor_min: Yup.number()
        .nullable()
        .typeError("Об'єм повинен бути числом")
        .min(0.0, "Об'єм не може бути меншим за нуль")
        .test('is-valid-number', 'Об\'єм повинен бути числом', (value) => !isNaN(value)),
    motor_max: Yup.number()
        .nullable()
        .typeError("Об\'єм повинен бути числом")
        .min(0.0, "Об\'єм не може бути меншим за нуль")
        .when('motor_min', (motor_min, schema) => {
            return motor_min
                ? schema.min(motor_min, 'Макс. Об\'єм не може бути меншим за Мін. Об\'єм')
                : schema;
        })
});
