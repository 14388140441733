// src/Components/Slider/PhotoSlider.js
import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Pagination, Zoom, Mousewheel, Virtual } from 'swiper/modules';
import { FaImages } from "react-icons/fa";
import BackBtn from '../Layout/BackBtn';
import { PhotoProvider, PhotoView } from 'react-photo-view';


import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/a11y';
import 'swiper/css/mousewheel';
import 'swiper/css/zoom';
import 'swiper/css/virtual';

const ViewGallery = ({ photos, visible, onClose, index, onIndexChange }) => {
    return (
        <PhotoProvider
            images={photos.map((photo, i) => ({
                src: `https://miniapp.carzoneua.com${photo.photo_url}`,
                key: i
            }))}
            visible={visible}
            onClose={onClose}
            index={index}
            onIndexChange={onIndexChange}
        />
    );
};

const PhotoSlider = ({ photos }) => {
    const [currentSlide, setCurrentSlide] = useState(1);

    const [galleryVisible, setGalleryVisible] = useState(false);
    const [galleryIndex, setGalleryIndex] = useState(0);

    const totalSlides = photos.length;

    return (
        <div className='relative'>

            <div className="flex items-center absolute top-2 left-2 bg-black bg-opacity-80 text-white px-2 py-1 rounded z-50">
                <BackBtn class="text-white dark:text-white" route={'/'} />
            </div>


            <ViewGallery
                photos={photos}
                visible={galleryVisible}
                onClose={() => setGalleryVisible(false)}
                index={galleryIndex}
                onIndexChange={setGalleryIndex}
            />

            <Swiper
                modules={[Pagination, Zoom, A11y, Mousewheel, Virtual]}
                className="rounded-t-lg"
                virtual

                lazy
                mousewheel={{
                    forceToAxis: true,
                    sensitivity: 0.5,
                    thresholdDelta: 18
                }}
                zoom
                spaceBetween={0}
                slidesPerView={1}
                pagination={{ clickable: true, type: 'progressbar' }}
                loop={true}
                onSlideChange={(swiper) => setCurrentSlide(swiper.realIndex + 1)}
                style={{ height: 500 }}
            // autoHeight={true}
            >
                {
                    photos
                        .sort((a, b) => a.order - b.order)
                        .map(({ photo_url }, i) => <SwiperSlide key={i} virtualIndex={i}
                            onClick={() => {
                                setGalleryIndex(i);
                                setGalleryVisible(true);
                            }}
                        >
                            <div className="swiper-zoom-container">
                                <img

                                    src={"https://miniapp.carzoneua.com" + photo_url} alt={"Slide " + i} />
                            </div>
                        </SwiperSlide>
                        )
                }
            </Swiper>

            <div className="flex items-center absolute bottom-2 left-2 bg-black bg-opacity-50 text-white px-2 py-1 rounded z-50">
                <FaImages className='inline-block mr-2' />
                <span>{currentSlide} / {totalSlides}</span>
            </div>

        </div>
    );
};

export default PhotoSlider;
