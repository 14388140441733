import { addPhotos, deletePhoto, getEditedPhotos } from '@/Services/api';
import React, { useEffect, useCallback, useState } from 'react';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import { useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useDropzone } from 'react-dropzone'; // Імпорт react-dropzone
import { useTelegram } from '@/Hooks/useTelegram';

const EditedPhoto = ({ adCarId, shouldReload }) => {
    const [editedPhotos, setEditedPhotos] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getAdEditedPhotos = useCallback(async () => {
        setIsLoading(true);
        try {
            const res = await getEditedPhotos(adCarId);
            setEditedPhotos(res.edit_photos);
        } catch (error) {
            // Помилка обробляється в інтерсепторі
        } finally {
            setIsLoading(false);
        }
    }, [adCarId]);

    useEffect(() => {
        getAdEditedPhotos();
    }, [shouldReload]);

    return (<>
        {
            editedPhotos.map(photo => (
                <div key={photo.id} style={{ margin: '10px', position: 'relative' }}>
                    <div className='absolute w-full h-full flex flex-col items-center justify-center bg-gray-900 bg-opacity-50 tg_text font-bold'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-8">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                        <p className='text-center'>Очікує підтвердження...</p>
                    </div>
                    <PhotoView src={`https://miniapp.carzoneua.com${photo.photo_url}`}>
                        <img
                            src={`https://miniapp.carzoneua.com${photo.photo_url}`}
                            alt={`Фото ${photo.id}`}
                            style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                        />
                    </PhotoView>
                </div>
            ))
        }
    </>)
}


const PhotoTab = ({ files }) => {
    const [photos, setPhotos] = useState(files);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const { id: adCarId } = useParams();
    const { tg } = useTelegram();

    const handleDelete = async (photoId) => {
        const confirmDelete = window.confirm('Ви впевнені, що хочете видалити це фото?');
        if (!confirmDelete) return;

        try {
            await deletePhoto(photoId);
            setPhotos(prevPhotos => prevPhotos.filter(photo => photo.id !== photoId));
            toast.success('Фото успішно видалено');
        } catch (error) {
            // Помилка обробляється в інтерсепторі
        }
    };

    const isSelectedFiles = selectedFiles.length > 0;

    const onDrop = useCallback((acceptedFiles) => {
        // Фільтруємо лише зображення
        const imageFiles = acceptedFiles.filter(file => file.type.startsWith('image/'));

        if (imageFiles.length !== acceptedFiles.length) {
            toast.warn('Деякі файли не є зображеннями і були пропущені');
        }

        // Перевірка розміру файлів (максимум 5MB)
        const validFiles = imageFiles.filter(file => {
            const isValidSize = file.size <= 5 * 1024 * 1024; // 5MB
            if (!isValidSize) {
                toast.warn(`Файл ${file.name} перевищує 5MB і був пропущений`);
            }
            return isValidSize;
        });

        setSelectedFiles(prevFiles => [...prevFiles, ...validFiles]);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: 'image/*',
        maxSize: 5 * 1024 * 1024, // 5MB
    });

    const handleRemoveSelectedFile = (file) => {
        setSelectedFiles(prevFiles => prevFiles.filter(f => f !== file));
    };

    const handleSaveChanges = useCallback(async () => {
        if (selectedFiles.length === 0) {
            toast.warning('Виберіть хоча б одне фото для додавання');
            return;
        }

        setIsUploading(true);
        try {
            await addPhotos(adCarId, selectedFiles);
            toast.success('Фото успішно додано');
            setSelectedFiles([]);
        } catch (error) {
            // Помилка обробляється в інтерсепторі
        } finally {
            setIsUploading(false);
        }
    }, [adCarId, selectedFiles]);

    useEffect(() => {
        if (isSelectedFiles) {
            tg.MainButton.show();
            tg.MainButton.setParams({
                text: 'Зберегти зміни',
                is_active: true,
            });
            tg.MainButton.onClick(handleSaveChanges);
        } else {
            tg.MainButton.hide();
        }

        return () => {
            tg.MainButton.offClick(handleSaveChanges);
        };
    }, [isSelectedFiles, tg, handleSaveChanges]);

    return (
        <div className='p-4'>
            {isLoading ? (
                <p>Завантаження фото...</p>
            ) : (
                <PhotoProvider>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {photos.map(photo => (
                            <div key={photo.id} style={{ margin: '10px', position: 'relative' }}>
                                <PhotoView src={`https://miniapp.carzoneua.com${photo.photo_url}`}>
                                    <img
                                        src={`https://miniapp.carzoneua.com${photo.photo_url}`}
                                        alt={`Фото ${photo.id}`}
                                        style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                                    />
                                </PhotoView>
                                <button
                                    onClick={() => handleDelete(photo.id)}
                                    className={`flex items-center gap-2 justify-center uppercase text-lg font-semibold text-white w-10 h-10 rounded red_text absolute top-0 right-0`}
                                >
                                    &times;
                                </button>
                            </div>
                        ))}

                        <EditedPhoto adCarId={adCarId} shouldReload={isUploading} />
                    </div>
                </PhotoProvider>
            )}

            <div className=''>
                <div
                    {...getRootProps()}
                    className='
                        border-2 border-dashed border-gray-300 tg_text p-10 text-center
                    '
                >
                    <input {...getInputProps()} />
                    {
                        isDragActive ?
                            <p>Перетягніть файли сюди...</p> :
                            <p>Натисніть або перетягніть фото сюди для вибору</p>
                    }
                </div>

                {selectedFiles.length > 0 && (
                    <div style={{ marginTop: '10px' }}>
                        <h3>Вибрані фото:</h3>
                        <ul>
                            {selectedFiles.map((file, index) => (
                                <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                    <span style={{ flexGrow: 1 }}>{file.name} ({(file.size / 1024).toFixed(2)} KB)</span>
                                    <button
                                        onClick={() => handleRemoveSelectedFile(file)}
                                        style={{
                                            background: 'red',
                                            color: 'white',
                                            border: 'none',
                                            borderRadius: '3px',
                                            padding: '2px 5px',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        Видалити
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                {tg?.platform === "unknown" && <button
                    onClick={handleSaveChanges}
                    disabled={selectedFiles.length === 0 || isUploading}
                    style={{
                        marginTop: '10px',
                        padding: '10px 20px',
                        backgroundColor: '#4CAF50',
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: (selectedFiles.length === 0 || isUploading) ? 'not-allowed' : 'pointer',
                    }}
                >
                    {isUploading ? 'Завантаження...' : 'Зберегти зміни'}
                </button>}
            </div>


        </div>
    );
};

export default PhotoTab;