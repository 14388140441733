import React, { useState, useEffect } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

export const MyRangeSlider = ({
    min = 0,
    max = 100,
    step = 1,
    defaultValue = [0, 100],
    value,
    onChange,
    unit = "",
    label = "",
    marks = {},
}) => {
    console.log("marks: ", Object.keys(marks).length > 0);
    return (
        <div className={`flex flex-col gap-2 ${Object.keys(marks).length > 0 ? 'mb-12' : 'mb-6'}`}>
            {/* Заголовок */}
            {label && (
                <div className="text-base tg_text flex justify-between items-center mb-2">
                    <span>{label}</span>
                    <span className="text-sm tg_hint">
                        {value[0]}{unit} - {value[1]}{unit}
                    </span>
                </div>
            )}
            {/* Слайдер */}
            <div className="px-6">
                <Slider
                    range
                    min={min}
                    max={max}
                    defaultValue={defaultValue}
                    step={step}
                    value={value}
                    onChange={onChange}
                    marks={marks}
                />
            </div>
        </div>
    );
};


export const ExponentialSlider = ({ onChange, iniValue, marks, ranges, percentRanges }) => {
    // Функція округлення до сотень
    const roundToHundreds = (num) => Math.round(num / 100) * 100;


    // Перетворення з реальних значень у відсоткові позиції
    const valueToPercent = (value) => {
        for (let i = 0; i < ranges.length; i++) {
            const { min, max, scale } = ranges[i];
            if (value >= min && value <= max) {
                const range = max - min;
                const relativeValue = (value - min) / range;
                return percentRanges[i] + relativeValue * scale;
            }
        }
        return 100; // Для значень поза межами
    };

    // Перетворення з відсоткових позицій у реальні значення
    const percentToValue = (percent) => {
        for (let i = 0; i < ranges.length; i++) {
            const { min, max, scale } = ranges[i];
            if (percent >= percentRanges[i] && percent <= percentRanges[i + 1]) {
                const relativePercent = (percent - percentRanges[i]) / scale;
                const rawValue = min + relativePercent * (max - min);
                return roundToHundreds(rawValue); // Округлення до сотень
            }
        }
        return roundToHundreds(ranges[ranges.length - 1].max); // Для значень поза межами
    };

    // Обробка зміни значень
    const handleChange = (percentValues) => {
        const realValues = percentValues.map(percentToValue);
        onChange(realValues);
    };



    return (
        <div className="flex flex-col gap-2">
            <div className="px-6">
                <Slider
                    range
                    min={0}
                    max={100}
                    step={0.1}
                    value={iniValue.map(valueToPercent)}
                    onChange={handleChange}
                    marks={marks}
                />
            </div>
        </div>
    );
};


export default {};
