
import React from "react";
import useCarStore from '@/Store/useCarStore';
import { MyRangeSlider } from "@/Components/Buttons/RangeSlider";

const MileageFilter = React.memo(() => {
    const tempFilters = useCarStore((state) => state.tempFilters);
    const setTempFilters = useCarStore((state) => state.setTempFilters);

    const handleChange = ([min, max]) => {
        setTempFilters({ mileage_min: min, mileage_max: max });
    };

    const marks = {
        0: "0",
        250: "250 тис.",
        500: "500 тис.",
        750: "750 тис."
    };

    return (<div className="mb-4">
        <MyRangeSlider
            label="Пробіг"
            min={0}
            max={750}
            step={10}
            unit=" тис.км."
            value={[tempFilters.mileage_min || 0, tempFilters.mileage_max || 750]}
            onChange={handleChange}
            marks={marks}
        />
    </div>);
});

export default MileageFilter;