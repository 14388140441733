
import React from "react";
import useCarStore from '@/Store/useCarStore';
import { MyRangeSlider } from "@/Components/Buttons/RangeSlider";

const YearRangeSlider = React.memo(() => {
    const tempFilters = useCarStore((state) => state.tempFilters);
    const setTempFilters = useCarStore((state) => state.setTempFilters);

    const handleChange = ([min, max]) => {
        setTempFilters({ car_year_min: min, car_year_max: max });
    };

    return (
        <MyRangeSlider
            label="Рік"
            min={1980}
            max={2024}
            step={1}
            unit=" рік"
            value={[tempFilters.car_year_min || 1980, tempFilters.car_year_max || 2024]}
            onChange={handleChange}
        />
    );
});

export default YearRangeSlider;